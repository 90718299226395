import * as React from "react"

const Pricing = () => {
    return (
        <div className="price">
            <div className="container">
                <div className="section-header">
                    <p>Pricing Plan</p>
                    <h2>Top up your account</h2>
                    <h2>and get started at once!</h2>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="price-item">
                            <div className="price-header">
                                <div className="price-icon">
                                    <i className="fa fa-battery-1" aria-label="price-icon"></i>
                                </div>
                                <div className="price-title">
                                    <h2>Tryout</h2>
                                </div>
                                <div className="price-pricing">
                                    <h2><small>$</small>72</h2>
                                </div>
                            </div>
                            <div className="price-body">
                                <div className="price-des">
                                    <ul>
                                        <li>3 hours tutoring</li>
                                        <li>&nbsp;</li>
                                        <li>homework assignments</li>
                                        <li>&nbsp;</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-footer">
                                <div className="price-action">
                                    <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=ruben%40lemiengre%2einfo&lc=BE&item_name=Tryout%20(id%3A%20886)&amount=72%2e00&currency_code=USD&button_subtype=services&bn=PP%2dBuyNowBF%3abtn_buynowCC_LG%2egif%3aNonHosted&return=https%3A%2F%2Fwww.chinesetutor.org%2F"><i className="fa fa-shopping-cart"></i>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="price-item featured-item">
                            <div className="price-header header-white">
                                <div className="price-icon">
                                    <i className="fa fa-battery-half" aria-label="price-icon"></i>
                                </div>
                                <div className="price-title">
                                    <h2>Basic package</h2>
                                </div>
                                <div className="price-pricing">
                                    <h2><small>$</small>192</h2>
                                </div>
                            </div>
                            <div className="price-body">
                                <div className="price-des">
                                    <ul>
                                        <li>8 hours tutoring</li>
                                        <li>+ 1 hour for free</li>
                                        <li>homework assignments</li>
                                        <li>change your tutor any time</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-footer">
                                <div className="price-action">
                                    <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=ruben%40lemiengre%2einfo&lc=BE&item_name=Basic%20(id%3A%20886)&amount=192%2e00&currency_code=USD&button_subtype=services&bn=PP%2dBuyNowBF%3abtn_buynowCC_LG%2egif%3aNonHosted&return=https%3A%2F%2Fwww.chinesetutor.org%2F"><i className="fa fa-shopping-cart"></i>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="price-item">
                            <div className="price-header">
                                <div className="price-icon">
                                    <i className="fa fa-battery" aria-label="price-icon"></i>
                                </div>
                                <div className="price-title">
                                    <h2>Extended package</h2>
                                </div>
                                <div className="price-pricing">
                                    <h2><small>$</small>360</h2>
                                </div>
                            </div>
                            <div className="price-body">
                                <div className="price-des">
                                    <ul>
                                        <li>15 hours tutoring</li>
                                        <li>+ 3 hours for free</li>
                                        <li>homework assignments</li>
                                        <li>change your tutor any time</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="price-footer">
                                <div className="price-action">
                                    <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=ruben%40lemiengre%2einfo&lc=BE&item_name=Extended%20(id%3A%20886)&amount=360%2e00&currency_code=USD&button_subtype=services&bn=PP%2dBuyNowBF%3abtn_buynowCC_LG%2egif%3aNonHosted&return=https%3A%2F%2Fwww.chinesetutor.org%2F"><i className="fa fa-shopping-cart"></i>Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing
