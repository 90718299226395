import * as React from "react"
import Carousel from "react-alice-carousel"
// import 'react-alice-carousel/lib/alice-carousel.css';

const carouselRespData = {
  0: {
    items: 1,
  },
  768: {
    items: 2,
  },
  992: {
    items: 3,
  },
}

const Testimonials = () => {
  return (
    <div className="testimonial">
      <div className="container">
        <div className="section-header">
          <p>Client testimonials</p>
          <h2>What our students say about our tutoring</h2>
        </div>
        <div className="owl-carousel testimonials-carousel">
          <Carousel
            animationDuration={6000}
            infinite={true}
            responsive={carouselRespData}
            disableButtonsControls={true}
            disableDotsControls={false}
            keysControlDisabled={false}
            autoPlay={true}
            controlsStrategy="alternate"
            mouseTracking={true}
          >
            <div className="testimonial-item">
              <div className="testimonial-img">
                <img
                  src="img/testimonial-1.jpg"
                  alt="testimonial Kevin's mom"
                />
              </div>
              <div className="testimonial-content">
                <p>
                  Even with Kevin's short attention span, his tutor - miss
                  Angela - has been amazing at teaching him to speak some basic
                  Chinese sentences in a very short time.
                </p>
                <h3>Kevin's mom</h3>
                <h4>2nd grade</h4>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="testimonial-img">
                <img src="img/testimonial-2.jpg" alt="testimonial Angela" />
              </div>
              <div className="testimonial-content">
                <p>
                  As a Master's student in Sinology, the tutors at ChineseTutor
                  have been a great help to improve my pronunciation, which is
                  very hard to do outside of a native environment.
                </p>
                <h3>Erika</h3>
                <h4>Sinology student</h4>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="testimonial-img">
                <img src="img/testimonial-3.jpg" alt="testimonial Deshawn" />
              </div>
              <div className="testimonial-content">
                <p>
                  For my business, it's important to be able to communicate very
                  well with my local suppliers. Studying business Chinese
                  through ChineseTutor is making me more confident speaking in
                  Chinese.
                </p>
                <h3>Deshawn</h3>
                <h4>businessman in Guangzhou</h4>
              </div>
            </div>
            <div className="testimonial-item">
              <div className="testimonial-img">
                <img src="img/testimonial-4.jpg" alt="testimonial Lily" />
              </div>
              <div className="testimonial-content">
                <p>
                  I often have to accompany Chinese clients to prospective
                  property sites. Thanks to ChineseTutor.org, I can now welcome
                  them with some basic Mandarin Chinese, which always amazes
                  them!
                </p>
                <h3>Lily</h3>
                <h4>real estate agent</h4>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Testimonials
