import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const WhyChooseUs = () => {
    return (
        <div className="feature">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="section-header left">
                            <p>Why Choose Us</p>
                            <h2>Expert tutors and world class service</h2>
                        </div>
                        <p>
                            Not only do we offer a selection of high-quality, experienced teachers. We also put everything in place to ensure you get the most out of your time, money and effort. We are only happy when you are.
                        </p>
                        <a className="btn" href="#!">Learn More</a>
                    </div>
                    <div className="col-md-7">
                        <div className="row align-items-center feature-item">
                            <div className="col-5">
                                <StaticImage src="../images/feature-1.jpg" width={300} height={200} alt="flexibility"/>
                            </div>
                            <div className="col-7">
                                <h3>High flexibility</h3>
                                <p>
                                    You study at your own pace. Our tutors will create a plan & schedule based on your specific needs and means.
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center feature-item">
                            <div className="col-5">
                                <StaticImage src="../images/feature-2.jpg" width={300} height={200} alt="reasonable prices"/>
                            </div>
                            <div className="col-7">
                                <h3>Reasonable Prices</h3>
                                <p>
                                    We don't charge steep prices but instead offer high quality tutoring and count on your repeat business.
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center feature-item">
                            <div className="col-5">
                                <StaticImage src="../images/feature-3.jpg" width={300} height={200} alt="small commitment"/>
                            </div>
                            <div className="col-7">
                                <h3>Small commitment</h3>
                                <p>
                                    You can start with our trial package to see if our tutoring formula works for you. With the more extended packages you can always change tutors if you are not 100% satisfied.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs
