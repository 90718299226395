import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const TeacherQuality = () => {
    return (
        <div className="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <div className="about-img">
                            <StaticImage src="../images/about2.jpg" width={561} height={600} alt="logo"/>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="about-text">
                            <h2>High quality teachers</h2>
                            <p>All our tutors are experienced and certified bilingual teachers, personally vetted by us to ensure that the tutoring you receive meets the highest standards.
                                <br/>We take a hands on approach and only work with highly educated professionals with a passion to help students thrive.
                                <br/>We work closely with both parents and students to ensure that each student’s specific needs are met.
                            </p>
                            <p>
                                At <b>ChineseTutor.org</b> we strive to push our students towards their full potential to better prepare them for the future.
                                <br/>If you go through the testimonials of our past students you will understand why people keep coming back to us.
                            </p>
                            <p>
                                Please don't hesitate to request a free consultation, so we can get to know you better and understand your specific tutoring needs. That way we can pair you up with the most suitable teacher.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeacherQuality
