import * as React from "react"
import { Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import useAuth from "../util/useStrapiAuth"

const TopTutors = ({ images }) => {
  const { isAuthenticated } = useAuth()
  return (
    <div className="team">
      <div className="container">
        <div className="section-header">
          <p>Team Members</p>
          <h2>Meet Our Top Tutors</h2>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="team-item">
              <div className="team-img">
                <GatsbyImage
                  image={getImage(images?.picAngela)}
                  alt="teacher Angela"
                />
              </div>
              <div className="team-text">
                <h2>Angela</h2>
                <h3>Kindergarten Chinese teacher</h3>
                <div className="team-social">
                  <div className="rating">
                    <label htmlFor="st1-1" aria-label="st1-1"></label>
                    <input type="checkbox" id="st1-1" value="1" />
                    <label htmlFor="st1-2" aria-label="st1-2"></label>
                    <input type="checkbox" id="st1-2" value="2" />
                    <label htmlFor="st1-3" aria-label="st1-3"></label>
                    <input type="checkbox" id="st1-3" value="3" />
                    <label htmlFor="st1-4" aria-label="st1-4"></label>
                    <input type="checkbox" id="st1-4" value="4" />
                    <label htmlFor="st1-5" aria-label="st1-5"></label>
                    <input
                      type="checkbox"
                      id="st1-5"
                      value="5"
                      readOnly
                      checked
                    />
                  </div>
                  <div className="break">
                    <Link
                      className="book-btn"
                      to={
                        isAuthenticated
                          ? "/dashboard/booking/tutor/angela"
                          : "/login"
                      }
                      state={{ redirect: "/dashboard/booking/tutor/angela" }}
                    >
                      Book now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team-item">
              <div className="team-img">
                <GatsbyImage
                  image={getImage(images?.picXiaoWang1)}
                  alt="teacher Xiao Wang"
                />
              </div>
              <div className="team-text">
                <h2>Xiao Wang</h2>
                <h3>Business Chinese for adults</h3>
                <div className="team-social">
                  <div className="rating">
                    <label htmlFor="st2-1" aria-label="st2-1"></label>
                    <input type="checkbox" id="st2-1" value="1" />
                    <label htmlFor="st2-2" aria-label="st2-2"></label>
                    <input type="checkbox" id="st2-2" value="2" />
                    <label htmlFor="st2-3" aria-label="st2-3"></label>
                    <input type="checkbox" id="st2-3" value="3" />
                    <label htmlFor="st2-4" aria-label="st2-4"></label>
                    <input
                      type="checkbox"
                      id="st2-4"
                      value="4"
                      readOnly
                      checked
                    />
                    <label htmlFor="st2-5" aria-label="st2-5"></label>
                    <input type="checkbox" id="st2-5" value="5" />
                  </div>
                  <div className="break">
                    <Link
                      className="book-btn"
                      to={
                        isAuthenticated
                          ? "/dashboard/booking/tutor/xiaowang"
                          : "/login"
                      }
                      state={{ redirect: "/dashboard/booking/tutor/xiaowang" }}
                    >
                      Book now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team-item">
              <div className="team-img">
                <GatsbyImage
                  image={getImage(images?.picXiaoLiu1)}
                  alt="teacher Xiao Liu"
                />
              </div>
              <div className="team-text">
                <h2>Miss Liu</h2>
                <h3>Middle school, high school</h3>
                <div className="team-social">
                  <div className="rating">
                    <label htmlFor="st3-1" aria-label="st3-1"></label>
                    <input type="checkbox" id="st3-1" value="1" />
                    <label htmlFor="st3-2" aria-label="st3-2"></label>
                    <input type="checkbox" id="st3-2" value="2" />
                    <label htmlFor="st3-3" aria-label="st3-3"></label>
                    <input type="checkbox" id="st3-3" value="3" />
                    <label htmlFor="st3-4" aria-label="st3-4"></label>
                    <input type="checkbox" id="st3-4" value="4" />
                    <label htmlFor="st3-5" aria-label="st3-5"></label>
                    <input
                      type="checkbox"
                      id="st3-5"
                      value="5"
                      readOnly
                      checked
                    />
                  </div>
                  <div className="break">
                    <Link
                      className="book-btn"
                      to={
                        isAuthenticated
                          ? "/dashboard/booking/tutor/missliu"
                          : "/login"
                      }
                      state={{ redirect: "/dashboard/booking/tutor/missliu" }}
                    >
                      Book now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team-item">
              <div className="team-img">
                <GatsbyImage
                  image={getImage(images?.picJessie1)}
                  alt="teacher Jessie"
                />
              </div>
              <div className="team-text">
                <h2>Jessie</h2>
                <h3>Chinese for adults (all levels)</h3>
                <div className="team-social">
                  <div className="rating">
                    <label htmlFor="st4-1" aria-label="st4-1"></label>
                    <input type="checkbox" id="st4-1" value="1" />
                    <label htmlFor="st4-2" aria-label="st4-2"></label>
                    <input type="checkbox" id="st4-2" value="2" />
                    <label htmlFor="st4-3" aria-label="st4-3"></label>
                    <input type="checkbox" id="st4-3" value="3" />
                    <label htmlFor="st4-4" aria-label="st4-4"></label>
                    <input
                      type="checkbox"
                      id="st4-4"
                      value="4"
                      readOnly
                      checked
                    />
                    <label htmlFor="st4-5" aria-label="st4-5"></label>
                    <input type="checkbox" id="st4-5" value="5" />
                  </div>
                  <div className="break">
                    <Link
                      className="book-btn"
                      to={
                        isAuthenticated
                          ? "/dashboard/booking/tutor/jessie"
                          : "/login"
                      }
                      state={{ redirect: "/dashboard/booking/tutor/jessie" }}
                    >
                      Book now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopTutors
