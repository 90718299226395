import * as React from "react"

const HowItWorks = () => {
    return (
        <div className="price">
            <div className="container">
                <div className="section-header">
                    <p>Procedure</p>
                    <h2>How it works</h2>
                </div>
                <div className="et_pb_section et_pb_section_4 et_section_regular" >
                    <div className="et_pb_row et_pb_row_5 et_pb_gutters2">
                        <div className="et_pb_column et_pb_column_1_3 et_pb_column_7  et_pb_css_mix_blend_mode_passthrough">
                            <div className="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_0 et_animated  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
                                <div className="et_pb_blurb_content">
                                    <div className="et_pb_main_blurb_image">
                                        <span className="et_pb_image_wrap">
                                            <picture>
                                                <source
                                                    srcSet="/img/Icon-1.webp"
                                                    type="image/webp"   alt="step 1" className="et-waypoint et_pb_animation_off"/>
                                                    <img  alt="step 1" className="et-waypoint et_pb_animation_off"
                                                          src="/img/Icon-1.png" />
                                            </picture>
                                        </span>
                                    </div>
                                    <div className="et_pb_blurb_container">
                                        <h4 className="et_pb_module_header">
                                            <span>Free Sign Up</span>
                                        </h4>
                                        <div className="et_pb_blurb_description">
                                            <div className="cmsmasters_icon_list_item_text">
                                                <p>Sign up for a free account to become a member.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="et_pb_column et_pb_column_1_3 et_pb_column_8  et_pb_css_mix_blend_mode_passthrough">
                            <div className="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_1 et_animated  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
                                <div className="et_pb_blurb_content">
                                    <div className="et_pb_main_blurb_image">
                                        <span className="et_pb_image_wrap">
                                            <picture>
                                                <source srcSet="/img/Icon-2.webp"
                                                        type="image/webp"   alt="step 2" className="et-waypoint et_pb_animation_off" />
                                                    <img  alt="step 2" className="et-waypoint et_pb_animation_off"
                                                          src="/img/Icon-2.png" />
                                            </picture>
                                        </span>
                                    </div>
                                    <div className="et_pb_blurb_container">
                                        <h4 className="et_pb_module_header">
                                            <span>Top up Your Account</span>
                                        </h4>
                                        <div className="et_pb_blurb_description">
                                            <p><span>Choose from our offer of packages to add lesson time to your account.</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="et_pb_column et_pb_column_1_3 et_pb_column_9  et_pb_css_mix_blend_mode_passthrough et-last-child">
                            <div className="et_pb_with_border et_pb_module et_pb_blurb et_pb_blurb_2 et_animated  et_pb_text_align_left  et_pb_blurb_position_left et_pb_bg_layout_light">
                                <div className="et_pb_blurb_content">
                                    <div className="et_pb_main_blurb_image">
                                        <span className="et_pb_image_wrap">
                                            <picture>
                                                <source srcSet="/img/Icon-3.webp"
                                                        type="image/webp"   alt="step 3" className="et-waypoint et_pb_animation_off" />
                                                    <img  alt="step 3" className="et-waypoint et_pb_animation_off"
                                                          src="/img/Icon-3.png" />
                                            </picture>
                                        </span>
                                    </div>
                                    <div className="et_pb_blurb_container">
                                        <h4 className="et_pb_module_header">
                                            <span>Book a Live Online Class</span>
                                        </h4>
                                        <div className="et_pb_blurb_description">
                                            <p><span>Book a teacher to start your online learning experience.</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks
