import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const CustomizedTutoring = () => {
    return (
        <div className="service">
            <div className="container">
                <div className="section-header">
                    <p>Customized tutoring</p>
                    <h2>Tailored to your needs</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <StaticImage src="../images/service-3.jpg" width={210} height={140} alt="tutoring goals"/>
                                <h3>Your goals</h3>
                                <p>
                                    Whether you just want to improve your spoken Chinese, practice for HSK exams or learn business Chinese, we can accommodate you.
                                </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <StaticImage src="../images/service-2.jpg" width={210} height={140} alt="student age group"/>
                                <h3>Your age group</h3>
                                <p>
                                    Teaching you or your children, no matter what age, we have tutors who are specialized in any age group.
                                </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <StaticImage src="../images/service-1.jpg" width={210} height={140} alt="student level"/>
                                <h3>Your level</h3>
                                <p>
                                    Beginner or advanced scholar, we have you covered.
                                </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="service-item">
                            <StaticImage src="../images/service-4.jpg" width={210} height={140} alt="tutoring schedule"/>
                                <h3>Your schedule</h3>
                                <p>
                                    We work with you at your own pace, and at a time that suits you best.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomizedTutoring
