import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Faq = () => {
  return (
    <div className="faqs">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="section-header left">
              <p>You Might Ask</p>
              <h2>Frequently Asked Questions</h2>
            </div>
            <StaticImage
              src="../images/faqs.jpg"
              width={600}
              height={400}
              alt="male teacher"
            />
          </div>
          <div className="col-md-7">
            <div id="accordion">
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link collapsed"
                    data-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="true"
                  >
                    <span>1</span> What materials do you use for tutoring?
                  </a>
                </div>
                <div
                  id="collapseOne"
                  className="collapse show"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Our classes are 100% customized to our students' level and
                    needs. <br />
                    When you sign up for lessons with ChineseTutor.org, your
                    tutor will first make an assessment of your current level
                    and needs, and discuss with you what your goals are and how
                    to reach them.
                    <br /> Then the tutor will recommend the appropriate text
                    books and other materials to you, chosen for your unique
                    situation.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link"
                    data-toggle="collapse"
                    href="#collapseTwo"
                  >
                    <span>2</span> How many hours do I need per week?
                  </a>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    You decide for yourself at what pace you want to study. We
                    can give you some guidance though.
                    <br />
                    <br />
                    There are basically 2 ways to approach your online studying:
                    <br />
                    <br />
                    1. <strong>full tutoring</strong>
                    <br />
                    Here you only study online with the help of your tutor over
                    Wechat, Skype or Whatsapp. Your tutor may give you homework
                    assignments to complete in between tutoring sessions.
                    <br />
                    You can choose a slow pace, for example 4 hours every
                    weekend, or a more intensive schedule, like 10 hours per
                    week.
                    <br />
                    Obviously, the intensive option will get you much further
                    much faster.
                    <br />
                    <br />
                    2. <strong>coached learning</strong>
                    <br />
                    This is a more independent approach where you mostly do
                    self-study and your tutor helps you by assessing your
                    progress, identifying weak areas, helping you set up your
                    weekly schedule and assigning tasks. This way, 2 hours per
                    week could be enough already.
                    <br />
                    It may not be the best option for beginners, for whom
                    pronunciation will be the biggest challenge.
                    <br />
                    <br />
                    We understand everybody has different needs and means, so we
                    offer full flexibility for you to create your own package,
                    with a personalized approach, pace and schedule.
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <a
                    className="card-link"
                    data-toggle="collapse"
                    href="#collapseFour"
                  >
                    <span>3</span> How can I become a tutor for
                    ChineseTutor.org?
                  </a>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Although we try to keep only a select group of high quality
                    tutors, we are always willing to consider welcoming very
                    qualified new tutors, especially with certain
                    specializations. <br />
                    If you are a certified and experienced professional who is
                    interested in fully remote tutoring work, please connect
                    with us through the <Link to="/contact">contact form</Link>
                  </div>
                </div>
              </div>
            </div>
            <a className="btn" href="/contact">
              Ask more
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
